.CotizacionHeader {
  color: #5e5e5e;
  font-size: 1.25rem;

  .banner-berit {
    max-height: 11.5rem;
    max-width: 100%;
  }

  .numero-cotizacion {
    background-color: #5e5e5e;
    color: #fff;
    padding: 1rem;
    margin: 0.5rem 0 1rem;
    text-align: start;
    font-size: 1.5rem;
  }

  .berit-banner-container {
    display: flex;
    justify-content: center;
  }

  .usuario-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1rem;
    font-size: 1.1rem;
  }

  .cliente {
    font-size: 1.3rem;
  }

  .cliente-contacto {
    font-size: 1.1rem;
  }

  .fecha {
    font-size: 1.1rem;
    align-items: flex-start;
    margin-top: 1.5rem;
  }

  .contacto {
    font-weight: bold;
  }

  .usuario {
    text-align: right;
  }

  .clickable {
    cursor: pointer;
  }

  p {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: 769px) {

    .cliente-contacto {
      font-size: 1.25rem;
    }

    .numero-cotizacion {
      font-size: 2rem;
      margin: 0 0 0.5rem;
      text-align: center;
    }

    .berit-banner-container {
      display: block;
    }

    .usuario-info {
      font-size: 1.25rem;
    }

    .fecha {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      justify-content: flex-end;

      span {
        margin-right: 0.5rem;
      }
    }

    .contacto {
      font-size: 1.3rem;
    }
  }

  @media screen and (max-width: 768px) {

    .usuario {
      text-align: center;
      padding: 0;
    }

    .banner-berit {
      max-height: 12rem;
    }
  }

  @media print {

    .numero-cotizacion {
      font-size: 2rem;
      margin: 0 0 0.5rem;
      text-align: center;
    }

    .berit-banner-container {
      display: block;
    }

    .usuario-info {
      font-size: 1.25rem;
    }

    .fecha {
      align-items: center;
      justify-content: center;
      display: flex;

      span {
        margin-right: 0.5rem;
      }
    }

    .cliente-contacto {
      padding-left: 2.5rem;
    }

    .contacto {
      font-size: 1.3rem;
    }
  }
}
