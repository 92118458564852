.EllipsisDropdown {

  .onHover .onHover-child {
    opacity: 0;
  }

  &:hover .onHover-child {
    opacity: 1;
  }
}
