.FilePondCustom {
  .filepond--credits {
    display: none;
  }
  .filepond--item {
    width: calc(50% - 0.5em);
  }
  @media (min-width: 768px) {
    .filepond--item {
      width: calc(33.33% - 0.5em);
    }
  }
  @media (min-width: 1200px) {
    .filepond--item {
      width: calc(25% - 0.5em);
    }
  }
}
