.DirectionFormHandler {

  .danger {
    color: red !important;
  }

  .clickable {
    cursor: pointer !important;
  }
}
