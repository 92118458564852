#profile-v1 {

  .cascading-admin-card .admin-up .fa {
    margin-right: 1rem;
  }

  .user-card .admin-up .data span {
    font-size: 15px;
  }

  .profile-card p {
    font-weight: 300;
  }
}

.user-card .about-text h4 {
  font-weight: 300;
}
