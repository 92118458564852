.CotizacionVer {
  padding-bottom: 3rem;

  .customMutedText {
    color: #878888;
    font-weight: 500;
    margin-bottom: 0;
  }

  .vendedorSign {
    @extend .customMutedText;

    color: rgb(94, 94, 94);
  }

  .vendedorPuesto {
    @extend .customMutedText;

    color: #ffd100;
  }

  .productImage {
    // display: none;
    width: 50%;
  }

  .item {
    display: flex;
    flex-wrap: wrap;

    .item-image {
      display: flex;
      justify-content: center;
      margin: 0.75rem 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .item-text {
      margin: 0.75rem 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .item-quantities {
      flex: 0 0 100%;
      max-width: 100%;

      span:first-of-type {
        display: block;
        color: #6c757d;
        font-size: 0.875rem;
      }

      div {
        margin: 0.75rem 0;

        &:last-of-type span:last-of-type {
          font-size: 1.125rem;
        }
      }
    }

    h5 {
      margin: 0.5rem 0;
      font-weight: bold;
    }

    p {
      margin: 0.5rem 0;
    }
  }

  @media (min-width: 768px) {
    .productImage {
      width: auto;
    }

    .item {
      align-items: center;

      .item-image {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 0.5rem;
      }

      .item-text {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 0 0.5rem;
      }

      .item-quantities {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 0.5rem;
        text-align: center;
      }
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  @media print {
    th {
      background-color: #ffd100 !important;
    }

    div {
      break-inside: avoid;
    }

    .productImage {
      // display: inline;
      width: auto;
    }

    .item {
      align-items: center;

      .item-image {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 0.5rem;
      }

      .item-text {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 0 0.5rem;
      }

      .item-quantities {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 0.5rem;
        text-align: center;
      }
    }

    .card {
      flex-direction: row !important;
    }

    .CotizacionCondicionesComerciales {
      section {
        page-break-after: always;
      }
    }
  }
}
