.CotizacionProducto {
  // border-bottom: 2px solid #aaa;
  padding: 0 1.75rem;

  .ImagenProducto {
    padding: 0;
    justify-content: center;
    max-width: 200px;

    img {
      max-width: 200px;
    }

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }

  .external-link i {
    opacity: 0;
  }

  &:hover .external-link i {
    opacity: 1;
  }

  .Calculadora > div {
    padding-left: 0;
    padding-right: 0;
  }

  .with-label {
    color: #4285f4;
    font-weight: 300;
    margin-left: -0.5rem;
    transform: translateY(10px) scale(0.75);
  }

  .input-group-prepend,
  .input-group-append {

    .input-group-text {
      color: #aaa;
    }
  }

  // .wrapperClassName {}

  .toolbarClassName {
    position: absolute;
    top: -30px;
    width: 97%;

    &[aria-hidden='true'] {
      pointer-events: none;
    }
  }

  .copy-precio-unitario {
    background-color: #aaa;
    pointer-events: none;
  }
}
