.Login {

  .view {
    background-image: url('../assets/img/bg-login.jpg');
    background-size: cover;
    height: 100vh;
  }

  .card {
    background-color: rgba(229, 228, 255, 0.2);
  }

  h6 {
    line-height: 1.7;
  }

  .form-check {
    margin-bottom: 0 !important;
  }

  .md-form label {
    color: #fff;
  }

  .md-form input {

    &[type='text']:focus:not([readonly]) + label,
    &[type='password']:focus:not([readonly]) + label,
    &[type='email']:focus:not([readonly]) + label {
      color: #8edef8;
    }

    &[type='text']:focus:not([readonly]),
    &[type='password']:focus:not([readonly]),
    &[type='email']:focus:not([readonly]) {
      border-bottom: 1px solid #8edef8;
      box-shadow: 0 1px 0 0 #8edef8;
    }
  }
}
