.BeritSelect {

  &.MuiFormControl-root {
    margin-bottom: 0.75em;

    // Label

    .MuiInputLabel-root {
      color: #757575;
      font-weight: 300;
      padding-right: 3em;

      &.MuiFormLabel-filled,
      &.Mui-focused {
        color: #4285f4;
      }
    }

    // Input

    .MuiInput-root {
      background-repeat: no-repeat;
      background-size: 1.25em;
      background-position: right 1.5em center;

      &:before {
        border-color: #ced4da;
      }

      &:hover:before {
        border-color: #949494;
      }

      &:after {
        border-color: #4285f4;
        border-width: 3px;
      }
    }

    // Remove MUI focused background

    .MuiSelect-select:focus {
      background-color: unset;
    }

    // Validation

    .was-submitted & {
      // Input

      .MuiInput-root {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");

        &.Mui-error {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        }

        &:after {
          border-color: #28a745;
        }

        &.Mui-error:after {
          border-color: #f44336;
        }
      }

      // Label

      .MuiInputLabel-root {

        &.Mui-focused {
          color: #28a745;
        }

        &.Mui-error {
          color: #f44336;
        }
      }
    }
  }
}
