.BeritSelectMainImage {
  z-index: 2;
  .ImageFrame {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }
  .chevronLeft {
    position: relative;
    font-size: 19px;
    padding-top: 25%;
    padding-bottom: 25%;
  }
  .chevronRight {
    @extend .chevronLeft;
  }
}
