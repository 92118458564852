.LiveSearch {

  .scrollbar {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .media {

    img {
      width: 150px;
    }

    .media-left {
      padding: 0 10px 0 0;
    }
  }

  .list-group-item-action {
    border-right: unset;
  }
}
