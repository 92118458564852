.BeritCheckbox {
  margin-bottom: 0.75em;

  .form-check {
    height: 32px;
    margin-top: 24px;
    padding-left: 0;
  }

  label:after {
    transition:
      box-shadow 0.1s ease-in-out,
      border 0.25s,
      background-color 0.25s,
      width 0.2s 0.1s,
      height 0.2s 0.1s,
      top 0.2s 0.1s,
      left 0.2s 0.1s !important;
  }

  label:hover:after,
  input:focus + label:after,
  input:active + label:after {
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
}
