.DataTablesMaster {
  .elipsisBreakLine {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  header {
    justify-content: space-between;
  }

  // .rdt_TableCell {
  // }

  .actions-container {
    opacity: 0;

    .btn-floating.btn-sm {
      height: 28px;
      width: 28px;
      margin: 4px;

      i {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }

  .badge {
    font-size: 100%;
  }

  .tooltip div span {
    white-space: nowrap;
  }

  .rdt_TableRow:hover .actions-container {
    opacity: 1;
    z-index: 1;
  }

  .rdt_TableBody {
    // overflow: initial;
    height: calc(100vh - 420px);
  }

  // .data-table-container {
  //   // height: calc(100vh - 8rem);
  //   // overflow-x: auto;
  //   width: auto;
  // }

  // .data-table-container tr {
  //   cursor: pointer;
  // }

  .data-table-container {
    height: calc(100vh - 9.125rem);
  }

  @media screen and (min-width: 992px) {
    .data-table-container {
      height: calc(100vh - 15.125rem);
    }
  }

  .data-table-container > div:first-of-type {
    // height: calc(100% - 110px);
    // overflow-y: auto;
    overflow: auto;
  }
}
