.CotizacionCondicionesComerciales {

  .bars {

    div {
      border-top: 2rem solid #5e5042;
      padding-top: 1.5rem;
    }

    h2 {
      padding: 1rem;
      font-weight: bolder;
      background-color: #f2f2f2;
      font-size: 1.6rem;
    }
  }

  .features-big {
    border-bottom: 1rem solid #f2f2f2;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    &:last-child {
      border-bottom: unset;
    }
  }

  @media screen and (min-width: 769px) {

    .bars {

      h2 {
        font-size: 2.5rem;
      }
    }
  }
}
