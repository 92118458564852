.CotizacionCrear {

  .date-picker {

    > div {
      display: flex;

      > div {

        &:before,
        &:hover:before {
          border-bottom: 1px solid #ced4da !important;
        }
      }

      label {
        transform: translateY(-14px) scale(0.8);
        color: #4285f4;
        font-size: 1rem;

        + div {
          margin-top: 0;
        }
      }
    }
  }

  .md-form label.active {
    color: #4285f4;
  }

  .banner-berit {
    max-height: 11.5rem;
    max-width: 100%;
  }

  .numero-cotizacion {
    background-color: #5e5e5e;
    color: #fff;
    padding: 1rem;
    margin: 3.75rem 0 3.5rem;
    text-align: center;
  }

  @media screen and (max-width: 768px) {

    .banner-berit {
      max-height: 12rem;
    }

    .numero-cotizacion {
      margin: 0.5rem 0 1rem;
    }
  }
}
