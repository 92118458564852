.slick-dots li button:before {
  color: white;
  opacity: 1;
  font-size: 14px;
}
.slick-dots li button:hover {
  opacity: 0.65;
}
div,
.slick-slider,
.slick-initialized {
  outline: 0;
}
@media print {
  .BeritLightboxComponent {
    display: none;
  }
}
