.CalculadoraVariants {

  .customInputCalc {
    margin-top: 52px !important;
    text-align: center !important;
  }

  .md-textarea {
    height: 16px;
    padding-top: 30px;
    padding-bottom: 0;
  }

  .col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md-addon {
    padding-left: 0;
    padding-right: 0;
  }
}
