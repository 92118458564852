.PedidoForm {

  .logo {
    order: 12;

    @media screen and (max-width: 768px) {
      order: initial;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  .numero-cotizacion {
    background-color: #5e5e5e;
    color: #fff;
    padding: 1rem;
    margin: 0 0 0.5rem;
    text-align: center;
  }

  .center-this {
    justify-content: center !important;
  }

  .subheader-gray {
    background-color: #5e5e5e;
    color: #fff;
    padding: 0.5rem;
    margin: 1.75rem 0 1.5rem;
    text-align: center;
  }

  .clickable {
    cursor: pointer;
  }

  .ourReadOnly {
    pointer-events: none;
    border-bottom: unset !important;
  }

  .danger {
    color: rgb(255, 27, 27);
  }

  .faX2 {
    font-size: 20px;
  }

  .removeTimes-md {
    @extend .danger, .faX2;

    margin-top: 12px;
    cursor: pointer;
  }
}
