body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;

  /* padding-top: 56px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.berit-gradient {
  background-image: linear-gradient(120deg, #ffd100 0%, #f6b700 100%);
}

.berit-text {
  color: #ffd100 !important;
}

/*

Amarillos Berit
#F6B700 - Amarillo obscuro
#FFD100 - Amarillo
#FCE300 - Amarillo claro

Negros Berit
#25282A - Negro
#545859 - Grus obscuro
#B7BCB4 - Gris claro

Secundarios Berit
#002B49 - Azul marino
#FF8200 - Naranja
#0090DA - Azul cielo
#0077CF - Azul cielo

Viejos Café Berit
#C4BCB7 - Café claro
#8C857B - Café
#776E64 - Café obscuro

*/
